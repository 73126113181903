import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { NativeCTALinks } from '../../shared/NativeCTALinks/NativeCTALinks'
import { FeedCategoryTxn } from '../constants'
import { FeedType } from '../../../api/gqlaxy/gql/generated/graphql'
import { useSelector } from 'react-redux'
import { RootState } from '../../../types/rootState'
import { selectNativeAccessFeature } from '../../../store/selectors/customerProfile'

export interface GallerySubscribeModalProps {
  feedCategory: FeedType
  onClose: () => void
}

export const MSG = defineMessages({
  findAPlace: {
    id: 'General.findAPlace',
    defaultMessage: 'Find a place...'
  },
  subscribedTo: {
    id: 'General.subscribedTo',
    defaultMessage: 'Subscribed to {category}'
  },
  content: {
    id: 'Gallery.subscribedContent',
    defaultMessage: 'If there are any new homes in our {title} gallery, you’ll get an email digest.'
  },
  downloadAppContent: {
    id: 'General.downloadAppContent',
    defaultMessage: 'Download the app to get push notifications delivered straight to your device.'
  },
  downloadTheApp: {
    id: 'General.downloadTheApp',
    defaultMessage: 'Download the app'
  },
  close: {
    id: 'General.close',
    defaultMessage: 'Close'
  }
})

export const GallerySubscribeModal = ({ feedCategory, onClose }: GallerySubscribeModalProps) => {
  const intl = useIntl()
  const hasNativeAccess = useSelector<RootState, boolean>(selectNativeAccessFeature)

  if (!FeedCategoryTxn[feedCategory]) {
    return null
  }

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent m={5}>
        <ModalHeader>
          <Heading size='lg' textAlign='left'>
            {intl.formatMessage(MSG.subscribedTo, { category: intl.formatMessage(FeedCategoryTxn[feedCategory]) })}
          </Heading>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody>
          <Text>{intl.formatMessage(MSG.content, { title: intl.formatMessage(FeedCategoryTxn[feedCategory]) })}</Text>
          <Text>{intl.formatMessage(MSG.downloadAppContent)}</Text>
        </ModalBody>

        <ModalFooter justifyContent={'center'}>
          <Box>
            {hasNativeAccess && (
              <NativeCTALinks
                tracking={{
                  guid: '6NU8QA8FBwaqxj30PgLWEN',
                  ui_context: 'GLOBAL_DOWNLOAD_NATIVE_APP_PAGE',
                  descriptor: 'Download the app'
                }}
              />
            )}
            <Button onClick={onClose} width='100%' mt={5} colorScheme='primary'>
              {intl.formatMessage(MSG.close)}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
