import {
  Button,
  Icon,
  Modal,
  Text,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  ButtonGroup,
  Heading,
  ModalBody,
  LinkButton,
  useTheme,
  ComponentTrackingType
} from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { HOMEOWNER_DIGEST_INTRO_MODULE } from '../../../../constants/tracking/trackingEventLocations/homeownerDigestTrackingEventLocation'
import { selectCompanyName, selectCustomerFirstName } from '../../../../store/selectors/customerProfile'
import { useSelector } from 'react-redux'
import { LeavingHomebotIllustration } from './LeavingHomebotIllustration'
import styles from './LeavingHomebotModal.module.scss'
import { ReactNode } from 'react'
import { useCreateClientEvent } from '../../../../hooks/useCreateClientEvent'
import { ClientEventInput, ClientEventName } from '../../../../api/gqlaxy/gql/generated/graphql'
import { selectRouteKey } from '../../../../store/selectors/routes'
import { selectHomeUuid } from '../../../../store/selectors/home'

export const MSG = defineMessages({
  leavingHomebotHeader: {
    id: 'LeavingHomebotModal.header',
    defaultMessage: 'Learn more'
  },
  youreOneStepCloser: {
    id: 'LeavingHomebotModal.subHeader',
    defaultMessage:
      "You're one step closer towards buying a new home, refinancing, or getting cash from your home's equity"
  },
  corporationNameOffersHomeFinancingOptions: {
    id: 'LeavingHomebotModal.corporationNameOffersHomeFinancingOptions',
    defaultMessage: '{corporationName} offers home financing options.'
  },
  byContinuing: {
    id: 'LeavingHomebotModal.byContinuing',
    defaultMessage: "By continuing, you'll go to their site in a new tab."
  },
  legalSmallPrint: {
    id: 'LeavingHomebotModal.legalSmallPrint',
    defaultMessage:
      'Homebot bears no responsibility for the accuracy, legality, or content of the external site. Contact {corporationName} for answers to questions regarding its content.'
  },
  customerLegalPrint: {
    id: 'LeavingHomebotModal.customerLegalPrint',
    defaultMessage:
      'Homebot bears no responsibility for the accuracy, legality, or content of the external site. Contact {customerName} for answers to questions regarding its content.'
  },
  maybeLater: {
    id: 'LeavingHomebotModal.maybeLater',
    defaultMessage: 'Maybe later'
  },
  goToSite: {
    id: 'LeavingHomebotModal.goToSite',
    defaultMessage: 'Go to site'
  }
})
interface LeavingHomebotModalProps {
  isOpen: boolean
  ctaLinkUrl: string
  renderModalBody?: () => ReactNode
  handleClose: () => void
  renderModalHeaderText?: string
  hideIllustration?: boolean
  scheduleACall?: boolean
  tracking?: ComponentTrackingType
}

export const LeavingHomebotModal = ({
  isOpen,
  ctaLinkUrl,
  renderModalHeaderText,
  handleClose,
  renderModalBody,
  tracking,
  hideIllustration = false,
  scheduleACall = false
}: LeavingHomebotModalProps) => {
  const corporationName = useSelector(selectCompanyName)
  const customerName = useSelector(selectCustomerFirstName)
  const intl = useIntl()
  const { theme } = useTheme()
  const { mutateAsync: createClientEvent } = useCreateClientEvent({ disableErrorPrompts: true })
  const isHome = selectRouteKey() === 'home'
  const homeUuId = useSelector(selectHomeUuid)

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading textAlign='left' as='h2' mb={0}>
            {renderModalHeaderText ? renderModalHeaderText : intl.formatMessage(MSG.leavingHomebotHeader)}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        {!hideIllustration && <LeavingHomebotIllustration />}
        <ModalBody>
          {renderModalBody ? (
            renderModalBody()
          ) : (
            <>
              <Text fontWeight='semibold' mb={4}>
                {intl.formatMessage(MSG.youreOneStepCloser)}
              </Text>
              <Text mb={4}>
                {intl.formatMessage(MSG.corporationNameOffersHomeFinancingOptions, {
                  corporationName: corporationName
                })}
              </Text>
              <Text mb={6}>{intl.formatMessage(MSG.byContinuing)}</Text>
            </>
          )}
        </ModalBody>
        <ModalFooter flexDirection={'column'}>
          <Text size='3xs' mb={4}>
            {scheduleACall
              ? intl.formatMessage(MSG.customerLegalPrint, {
                  customerName: customerName
                })
              : intl.formatMessage(MSG.legalSmallPrint, {
                  corporationName: corporationName
                })}
          </Text>
          <ButtonGroup
            spacing={3}
            flexDirection={'row'}
            alignSelf={'flex-end'}
            className={
              // typography.scss sets global anchor styles that were overriding the design system "link styled as button" styles
              // here we have to re-override the global styles to match the design system styles
              theme.isLightThemeType ? styles.anchorOverrideWhite : styles.anchorOverrideBlack
            }
          >
            <Button
              onClick={handleClose}
              colorScheme='primary'
              variant='ghost'
              tracking={{
                guid: '2wU6vXf8a6nSijoDHDU53d',
                ui_context: tracking ? tracking.ui_context : HOMEOWNER_DIGEST_INTRO_MODULE,
                descriptor: 'user dismissed the leave homebot modal'
              }}
            >
              {intl.formatMessage(MSG.maybeLater)}
            </Button>
            <LinkButton
              isExternal
              rightIcon={<Icon name='external-link' width={3} height={3} />}
              href={ctaLinkUrl}
              colorScheme='primary'
              tracking={{
                guid: '9rHnbW6rrREo63jtrXNxhB',
                ui_context: tracking ? tracking.ui_context : HOMEOWNER_DIGEST_INTRO_MODULE,
                descriptor: `link clicked ${ctaLinkUrl}`
              }}
              onClick={() => {
                // TODO: schedule a call client event as fast follow - remove this
                if (!scheduleACall) {
                  const clientEventInput: ClientEventInput = {
                    eventName: ClientEventName.LoanApplicationCtaClick,
                    // eventName: scheduleACall
                    //   ? ClientEventName.ScheduleACallCtaClick
                    //   : ClientEventName.LoanApplicationCtaClick,
                    properties: {
                      ...(scheduleACall ? { scheduleACallLinkUri: ctaLinkUrl } : { loanApplicationLinkUrl: ctaLinkUrl })
                    }
                  }

                  if (isHome) {
                    clientEventInput.homeId = homeUuId
                  }

                  createClientEvent({
                    clientEventInput
                  })
                }
                handleClose()
              }}
            >
              {intl.formatMessage(MSG.goToSite)}
            </LinkButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
