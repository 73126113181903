import { Box, Flex, Text } from '@homebotapp/hb-react-component-catalog'
import { ReactNode } from 'react'

interface ListingGalleryTileProps {
  icon: ReactNode
  title: string
  onClick?: () => void
}

export const ListingGalleryTile = ({ icon, title, onClick }: ListingGalleryTileProps) => {
  return (
    <Flex
      flex='1 0 auto'
      gap={2}
      align='center'
      direction='row'
      shadow={2}
      _hover={{
        borderColor: 'neutral.400',
        bg: 'neutral.200'
      }}
      onClick={onClick}
      cursor='pointer'
      px={3}
      borderWidth={1}
      borderRadius={16}
      width='fit-content'
      backgroundColor='neutral.50'
    >
      <Box py={1} flex='1 0 auto'>
        {icon}
      </Box>
      <Text
        flex='1 0 auto'
        as='span'
        whiteSpace='nowrap'
        m={0}
        textColor='text-neutral-900'
        py={1}
        size='2xs'
        fontWeight={600}
      >
        {title}
      </Text>
    </Flex>
  )
}
