import { Flex, Icon, IconButton } from '@homebotapp/hb-react-component-catalog'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientIsGuest } from '../../../store/selectors/client'
import { useGetFavoriteListings } from '../../../hooks/gallery/useGetFavoriteListings'
import { useMemo, useState } from 'react'
import { useToggleFavoriteListing } from '../../../hooks/listings/useToggleFavoriteListing'
import { isAuthTokenSupported } from '../../../auth'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'
import { clientEventSourceMapper } from '../Utils/clientEventSourceMapper'
import { useEffectOncePerSessionConditionally } from '../../../hooks'
import { useRecoilState } from 'recoil'
import { firstFavoritePropertyState } from '../GoogleAutoComplete/state/isFirstFavoriteProperty'
import { ListingFavoriteAlertModal } from '../ListingFavoriteAlertModal/ListingFavoriteAlertModal'

export interface ListingFavoriteBtnProps {
  listingId: string
  /** optional for impersonation */
  clientId?: string
}

export const ListingFavoriteBtn = ({ listingId, clientId }: ListingFavoriteBtnProps) => {
  /** true if impersonating */
  const isGuest = useSelector(selectClientIsGuest)
  const location = window.location.pathname

  const { data, refetch, isLoading: favoriteListingsIsLoading } = useGetFavoriteListings({ clientId })
  const favoriteListings = useMemo(() => data?.favoriteListings?.listings ?? [], [data?.favoriteListings?.listings])

  const { mutateAsync: toggleFavoriteListing, isLoading } = useToggleFavoriteListing()
  const dispatch = useDispatch()

  const isFavorite = useMemo(() => {
    return favoriteListings.some(listing => listing?.id === listingId)
  }, [favoriteListings, listingId])

  const [recoilFirstFavoriteProperty, setRecoilFirstFavoriteProperty] = useRecoilState(firstFavoritePropertyState)

  const setFirstFavoriteProperty = () => {
    if (favoriteListings && favoriteListings.length === 0) {
      setRecoilFirstFavoriteProperty('true')
    }
  }
  // set atom for first favorite property based on faoriteListings array
  useEffectOncePerSessionConditionally(
    setFirstFavoriteProperty,
    'View_listingFavoriteButton',
    favoriteListingsIsLoading === false
  )

  // stops all listing cards on page from showing the alert modal
  const [isFirstFavoriteProperty, setIsFirstFavoriteProperty] = useState(false)

  const handleFavoriteClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isAuthTokenSupported() || isGuest) {
      dispatch(toggleAuthModal(true))
      return
    }
    await toggleFavoriteListing({
      toggleFavoriteListingInput: {
        listingId: listingId,
        eventSource: clientEventSourceMapper(location)
      }
    })
    refetch()
    if (recoilFirstFavoriteProperty === 'true' && !isFavorite) {
      setIsFirstFavoriteProperty(true)
      setRecoilFirstFavoriteProperty('pending')
    }
  }

  if (isGuest) {
    return null
  }

  return (
    <>
      <Flex alignItems='center'>
        {!isLoading && (
          <IconButton
            size='sm'
            variant='outline'
            colorScheme='secondary'
            aria-label='Like this listing'
            bg='blackAlpha.700'
            _hover={{
              bg: 'blackAlpha.900',
              _light: {
                bg: 'whiteAlpha.900'
              }
            }}
            _light={{ bg: 'whiteAlpha.700' }}
            onClick={handleFavoriteClick}
            icon={
              isFavorite ? <Icon name='heart' color='brand.700' h={4} w={4} /> : <Icon name='heart-empty' h={4} w={4} />
            }
            tracking={{
              guid: 'mzG21EgHbdsHEJhwEaqdJZ',
              ui_context: clientEventSourceMapper(location),
              descriptor: isFavorite ? 'RemoveFavoriteListing' : 'AddFavoriteListing'
            }}
          />
        )}
      </Flex>
      {/* to do - fix, problem is this is rendering for all listings */}
      {recoilFirstFavoriteProperty !== 'false' && isFirstFavoriteProperty && (
        <ListingFavoriteAlertModal mode='initial' />
      )}
    </>
  )
}
