export const clientState = ({
  data = {
    homes: null
  },
  hasRequestedClient = false,
  hasRequestedHomes = false,
  loadingData = true,
  loadingHomes = false,
  error = null,
  fetchId = null,
  saveRequest = null,
  saveSuccess = null,
  saveError = null,
  flagForUnsubscribe = null,
  isGuest = false
} = {}) => ({
  data,
  hasRequestedClient,
  hasRequestedHomes,
  loadingData,
  loadingHomes,
  error,
  fetchId,
  saveRequest,
  saveSuccess,
  saveError,
  flagForUnsubscribe,
  isGuest
})

export const withClientState = client => ({
  client: clientState(client)
})

export const clientData = ({
  affordability,
  buyersAccess,
  clientAnswers,
  closeDate,
  closeReason,
  createdAt,
  debt,
  digestEmailUnsubscribed,
  downPayment,
  email,
  favoriteListingsAlertCadenceDays,
  firstName,
  hbbAccess,
  homes,
  id,
  income,
  invited,
  invitedType,
  lastName,
  leadSource,
  legacyRef,
  loadingData,
  loanTerm,
  locale,
  lockboxUid,
  marketInterests,
  meta,
  mobile,
  prequalifications,
  subscribedToBuyerDigest,
  type,
  updatedAt
} = {}) => ({
  affordability,
  buyersAccess,
  clientAnswers,
  closeDate,
  closeReason,
  createdAt,
  debt,
  digestEmailUnsubscribed,
  downPayment,
  email,
  favoriteListingsAlertCadenceDays,
  firstName,
  hbbAccess,
  homes,
  id,
  income,
  invited,
  invitedType,
  lastName,
  leadSource,
  legacyRef,
  loadingData,
  loanTerm,
  locale,
  lockboxUid,
  marketInterests,
  meta,
  mobile,
  prequalifications,
  subscribedToBuyerDigest,
  type,
  updatedAt
})

export const withClientData = c => ({
  data: clientData(c)
})

export const withHasRequestedHomes = (hasRequestedHomes = true) => ({
  hasRequestedHomes
})

export const addressData = ({ street, unit, zip } = {}) => ({
  street,
  unit,
  zip
})

export const withAddress = a => ({
  address: addressData(a)
})

export const homeData = ({ id, address } = {}) => ({
  id,
  address: addressData(address)
})

export const withHomeData = home => homeData(home)

export const withHomes = (...homes) => ({
  homes: homes.map(homeData)
})

export const withId = id => ({ id })
