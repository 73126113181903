import { UseMutationOptions, useQueryClient } from 'react-query'
import {
  UpdateFavoriteListingMutation,
  UpdateFavoriteListingMutationVariables
} from '../../api/gqlaxy/gql/generated/graphql'
import { useCallback } from 'react'
import { useUpdateFavoriteListingMutation } from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { QueryKey } from '../../constants/queryKey'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { useToast } from '@homebotapp/hb-react-component-catalog'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E, V> extends UseMutationOptions<D, E, V> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}
export const useUpdateFavoriteListing = (
  option: Option<UpdateFavoriteListingMutation, Error, UpdateFavoriteListingMutationVariables> = {}
) => {
  const queryClient = useQueryClient()

  const toast = useToast()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // Local endpoint
  })

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.GetPreferredAgent)
  }, [queryClient])

  return useUpdateFavoriteListingMutation(client, {
    onError: handleError,
    onSettled: handleSettled,
    ...options
  })
}
