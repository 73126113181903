import { ClientEventSource } from 'src/api/gqlaxy/gql/generated/graphql'

const urlPathnameToEventSourceMap: { [key: string]: ClientEventSource } = {
  'favorite-listings': ClientEventSource.FavoriteProperties, // part of for-you path so needs to come first
  gallery: ClientEventSource.Gallery,
  'for-you': ClientEventSource.ForYou,
  listing: ClientEventSource.ListingDetailed
}

/**
 * Maps the url pathname to the appropriate ClientEventSource
 * @param urlPathname string from useLocation().pathname
 * @returns ClientEventsource type
 */
export const clientEventSourceMapper = (urlPathname: string): ClientEventSource => {
  for (const [key, value] of Object.entries(urlPathnameToEventSourceMap)) {
    if (urlPathname.includes(key)) {
      return value
    }
  }
  return ClientEventSource.ListingsSearch
}
