import { ReactNode, useState } from 'react'
import { HOMEOWNER_DIGEST_INTRO_MODULE } from '../../../../constants/tracking/trackingEventLocations/homeownerDigestTrackingEventLocation'
import { LeavingHomebotModal } from '../LeavingHomebotModal'
import { Button, ButtonProps, ComponentTrackingType } from '@homebotapp/hb-react-component-catalog'

interface LeavingHomebotCtaButtonProps extends ButtonProps {
  dataQa?: string
  ctaLinkUrl?: string
  ctaButtonVariant?: 'ghost' | 'outline' | 'solid' | 'link'
  ctaButtonColorScheme: 'primary' | 'secondary' | 'brand'
  ctaButtonSize: 'xs' | 'sm' | 'md'
  className?: string
  tracking?: ComponentTrackingType
  renderModalBody?: () => ReactNode
  headerText?: string
  hideIllustration?: boolean
  scheduleACall?: boolean
  fullWidth?: boolean
  leftIcon?: React.ReactElement
  children: ReactNode & Omit<ReactNode, 'null' | 'undefined'> // the intl'd button text
}

export const LeavingHomebotCtaButton = ({
  dataQa,
  ctaLinkUrl,
  ctaButtonVariant,
  ctaButtonColorScheme,
  ctaButtonSize,
  className,
  headerText,
  renderModalBody,
  hideIllustration,
  scheduleACall,
  fullWidth,
  tracking,
  leftIcon,
  children,
  ...restProps
}: LeavingHomebotCtaButtonProps) => {
  const [isCtaModalOpen, setIsCtaModalOpen] = useState(false)

  if (!ctaLinkUrl) {
    return null
  }

  return (
    <>
      <Button
        data-qa={dataQa}
        className={className}
        onClick={() => setIsCtaModalOpen(true)}
        size={ctaButtonSize}
        width={fullWidth ? '100%' : ''}
        leftIcon={leftIcon}
        colorScheme={ctaButtonColorScheme}
        variant={ctaButtonVariant}
        tracking={
          tracking ?? {
            guid: 'qjrRRtg7QQenNSt5y4yZYo',
            ui_context: HOMEOWNER_DIGEST_INTRO_MODULE,
            descriptor: 'intro cta button clicked'
          }
        }
        {...restProps}
      >
        {children}
      </Button>
      <LeavingHomebotModal
        isOpen={isCtaModalOpen}
        renderModalBody={renderModalBody}
        renderModalHeaderText={headerText}
        handleClose={() => setIsCtaModalOpen(false)}
        hideIllustration={hideIllustration}
        scheduleACall={scheduleACall}
        ctaLinkUrl={ctaLinkUrl}
        tracking={tracking}
      />
    </>
  )
}
