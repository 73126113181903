import { UseMutationOptions, useQueryClient } from 'react-query'
import {
  ResetFavoriteListingsAlertCadenceMutation,
  ResetFavoriteListingsAlertCadenceMutationVariables
} from '../../api/gqlaxy/gql/generated/graphql'
import { useToast } from '@homebotapp/hb-react-component-catalog'
import { useIntl } from 'react-intl'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { useCallback } from 'react'
import { QueryKey } from '../../constants/queryKey'
import { useResetFavoriteListingsAlertCadenceMutation } from '../../api/gqlaxy/gql/generated/graphql-hooks'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E, V> extends UseMutationOptions<D, E, V> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}
export const useResetFavoriteListingsAlertCadence = (
  option: Option<
    ResetFavoriteListingsAlertCadenceMutation,
    Error,
    ResetFavoriteListingsAlertCadenceMutationVariables
  > = {}
) => {
  const queryClient = useQueryClient()

  const toast = useToast()
  const intl = useIntl()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // Local endpoint
  })

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        position: 'bottom-left'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.GetFavoriteListings)
  }, [queryClient])

  return useResetFavoriteListingsAlertCadenceMutation(client, {
    onError: handleError,
    onSettled: handleSettled,
    ...options
  })
}
