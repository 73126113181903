import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Link,
  LoadingSpinner,
  Switch,
  Text
} from '@homebotapp/hb-react-component-catalog'
import { FeedBase, FeedType, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { useGetFeed } from '../../../hooks/gallery/useFeed'
import { defineMessages, useIntl } from 'react-intl'
import { GalleryList } from '../GalleryList/GalleryList'
import { useLocation, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from '../../../types/rootState'
import { selectHomeValue } from '../../../store/selectors/home/metadata'
import { useToggleFeedSubscribed } from '../../../hooks/gallery/useToggleFeedSubscribed'
import { useState } from 'react'
import { GallerySubscribeModal } from '../GallerySubscribeModal/GallerySubscribeModal'
import { hasCustomerToken, isAuthTokenSupported } from '../../../auth'
import { SEARCH_PATH_HELPERS } from '../../../constants/navigation'
import { AssumablesLearnMoreModal } from '../AssumablesLearnMoreModal/AssumablesLearnMoreModal'
import { BuyerPerksLearnMoreModal } from '../BuyerPerksLearnMoreModal/BuyerPerksLearnMoreModal'
import { selectClientId } from '../../../store/selectors/client'
import { selectClientHomes } from '../../../store/selectors/client'
import { Waypoint } from 'react-waypoint'
import { useTrackingDebounceSetters } from '../../../hooks/tracking'
import { useTrackingContext } from '../../../providers/tracking'
import { FOR_YOU } from '../../../constants/tracking/trackingEventConstants'
import { snakeCaseStrings } from '../../../helpers/utils'

export interface FeedProps {
  feed: FeedBase
}

export const MSG = defineMessages({
  viewAll: {
    id: 'General.viewAll',
    defaultMessage: 'View all'
  },
  findAPlace: {
    id: 'General.findAPlace',
    defaultMessage: 'Find a place...'
  },
  for: {
    id: 'General.for',
    defaultMessage: 'For'
  },
  recentPriceDrop: {
    id: 'Gallery.priceDrops',
    defaultMessage: 'Price Drops'
  },
  recentPriceDropDescription: {
    id: 'Gallery.priceDropsDescription',
    defaultMessage:
      'See places with a reduced asking price, and subscribe to this gallery to receive automatic email updates!'
  },
  assumables: {
    id: 'Gallery.assumables',
    defaultMessage: 'Assumable Loans'
  },
  assumablesDescription: {
    id: 'Gallery.assumablesDescription',
    defaultMessage:
      "Ask your lender if you qualify for an assumable mortgage. You may be able to save by taking over the seller's low-rate loan!"
  },
  openHouses: {
    id: 'Gallery.openHouses',
    defaultMessage: 'Open Houses'
  },
  openHousesDescription: {
    id: 'Gallery.openHousesDescription',
    defaultMessage: 'See homes in person'
  },
  savedSearch: {
    id: 'General.savedSearch',
    defaultMessage: 'Saved Search'
  },
  favorites: {
    id: 'Gallery.favorites',
    defaultMessage: 'Favorites'
  },
  concessions: {
    id: 'Gallery.concessions',
    defaultMessage: 'Seller Concessions'
  },
  concessionsDescription: {
    id: 'Gallery.concessionsDescription',
    defaultMessage: 'Sellers cover part of your expenses'
  },
  recentlySold: {
    id: 'Gallery.recentlySold',
    defaultMessage: 'Recently Sold'
  },
  recentlySoldDescription: {
    id: 'Gallery.recentlySoldDescription',
    defaultMessage:
      'Explore to see what the market is like, and subscribe to this gallery to receive automatic email updates!'
  },
  yourStuff: {
    id: 'General.yourStuff',
    defaultMessage: 'Your Stuff'
  },
  localGallery: {
    id: 'General.localGallery',
    defaultMessage: 'Local Gallery'
  },
  forYou: {
    id: 'General.forYou',
    defaultMessage: 'For You'
  },
  help: {
    id: 'Gallery.help',
    defaultMessage: 'Let’s find your next home'
  },
  changeLocation: {
    id: 'General.changeLocation',
    defaultMessage: 'Change Location'
  },
  subscribe: {
    id: 'Gallery.subscribe',
    defaultMessage: 'Alerts off'
  },
  subscribed: {
    id: 'Gallery.subscribed',
    defaultMessage: 'Alerts on'
  },
  forHome: {
    id: 'Gallery.forHome',
    defaultMessage: 'For your home in'
  }
})

export const Feed = ({ feed }: FeedProps) => {
  const intl = useIntl()
  const isImpersonating = hasCustomerToken()
  const { trackingClient } = useTrackingContext()
  const homes = useSelector(selectClientHomes)
  const homeValue = useSelector<RootState, number>(selectHomeValue)
  const { mutateAsync: toggleSub } = useToggleFeedSubscribed()
  const [showSubscribedModal, setShowSubscribedModal] = useState(false)
  const { data, isFetching, refetch } = useGetFeed({
    feedId: feed?.id,
    options: {
      priceAnchor: homeValue ?? undefined
    }
  })
  const params = useParams<{ customerProfileId: string; clientId: string }>()
  const clientId = useSelector(selectClientId)
  const snakeCaseFeedType = snakeCaseStrings(feed.feedType)

  const [trackModuleViewed] = useTrackingDebounceSetters(
    () =>
      trackingClient?.moduleViewed({
        event_source: FOR_YOU,
        module_name: snakeCaseFeedType
      })
  )

  const toggleSubscription = async () => {
    if (!isAuthTokenSupported()) {
      return
    }
    await toggleSub({ feedId: feed.id })
    await refetch()
    if (data?.feed.subscribed) {
      return
    }
    setShowSubscribedModal(true)
  }

  const learnMoreAssumableButtonProps = {
    variant: 'link',
    colorScheme: 'primary',
    size: 'xs',
    width: 'fit-content',
    tracking: {
      guid: '2qHGXjzHKCCCPDKnBtaA7J',
      ui_context: 'For You Gallery Feed',
      descriptor: 'Info button to learn more about assumables'
    }
  }

  const renderGalleryTile = (titleKey: string, descriptionKey: string) => {
    if (!MSG[titleKey] || !MSG[descriptionKey]) {
      return null
    }

    const location =
      data?.feed?.homeId && homes?.find(home => home.uuid === data?.feed?.homeId)?.address?.zip
        ? homes?.find(home => home.uuid === data.feed.homeId).address.zip
        : feed.location.name
    return (
      <Box width='100%'>
        <Flex justifyContent='space-between' flexDirection={['column', 'row']}>
          <Flex flexDirection='column' width='100%'>
            <Flex gap={2} align='flex-end' justify='flex-start'>
              <Heading as='h3' m={0} variant='eyebrow' color='brand'>
                {intl.formatMessage(MSG[titleKey])}
              </Heading>
              {data?.feed?.listings && data.feed.listings.length > 0 && (
                <Link
                  href={SEARCH_PATH_HELPERS.gallery.buildPath(
                    params.customerProfileId,
                    feed.id,
                    feed?.feedType,
                    clientId
                  )}
                  size={'xs'}
                  mb='.25rem'
                  tracking={{
                    ui_context: 'Gallery_Feed',
                    descriptor: `Clicked on Feed ${feed?.feedType}`,
                    guid: `3GtcZMbe9HLD7YUKKiAyjg_${feed?.feedType}`
                  }}
                >
                  {intl.formatMessage(MSG.viewAll)}
                </Link>
              )}
            </Flex>
            <Heading mb={0} as='h4' size='xs' fontStyle='italic'>
              {intl.formatMessage(data?.feed?.homeId ? MSG.forHome : MSG.for)} {location}
            </Heading>
            <Text m={0} size='xs'>
              {intl.formatMessage(MSG[descriptionKey])}
              {data?.feed?.feedType === FeedType.Assumables && (
                <Text as='span' ml={1}>
                  <AssumablesLearnMoreModal buttonProps={learnMoreAssumableButtonProps} />
                </Text>
              )}
              {data?.feed?.feedType === FeedType.Concessions && (
                <Text as='span' ml={1}>
                  <BuyerPerksLearnMoreModal asLink={true} />
                </Text>
              )}
            </Text>
          </Flex>

          {data?.feed && !isImpersonating && (
            <FormControl width='100%' alignItems='center' as={Flex} justifyContent={['start', 'end']}>
              <FormLabel m={0} mr={1} p={0} htmlFor='unsubscribe' marginBottom='0'>
                {data.feed.subscribed ? intl.formatMessage(MSG.subscribed) : intl.formatMessage(MSG.subscribe)}
              </FormLabel>
              <Switch
                mt={2}
                mx={0}
                p={0}
                tracking={{
                  guid: '6NU8QA8FBwaqxj40PgLWEN',
                  ui_context: `For You Feeds Page - ${data.feed.feedType} gallery`,
                  descriptor: data.feed.subscribed
                    ? `Subscribe to ${data.feed.feedType} feed`
                    : `Unsubscribe from ${data.feed.feedType} feed`
                }}
                onChange={() => {
                  toggleSubscription()
                }}
                isChecked={data.feed.subscribed}
                id='subscribe-switch'
                name='subscribe-switch'
              />
            </FormControl>
          )}
        </Flex>
        {showSubscribedModal && (
          <GallerySubscribeModal
            feedCategory={data?.feed.feedType as FeedType}
            onClose={() => {
              setShowSubscribedModal(false)
            }}
          />
        )}
      </Box>
    )
  }

  if (isFetching) {
    return (
      <Flex justifyContent='center' mt={5}>
        <LoadingSpinner />
      </Flex>
    )
  }

  return (
    <Box mb={8}>
      {renderGalleryTile(feed.feedType, `${feed.feedType}Description`)}
      <Box mt={5}>
        <Waypoint onEnter={() => trackModuleViewed()} bottomOffset='25%' />
        <GalleryList
          feedType={feed.feedType}
          listings={data?.feed.listings.slice(0, 4) as ListingCompact[]}
          clientId={clientId as string}
        />
      </Box>
    </Box>
  )
}
