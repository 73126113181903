import statusForSale from '../../../assets/homes/img/statusForSale.svg'
import statusComingSoon from '../../../assets/homes/img/statusComingSoon.svg'
import statusPending from '../../../assets/homes/img/statusPending.svg'
import statusClosed from '../../../assets/homes/img/statusClosed.svg'
import statusHold from '../../../assets/homes/img/statusHold.svg'
import statusNotAvailable from '../../../assets/homes/img/statusNotAvailable.svg'
import { Box, Flex, Text, useTheme } from '@homebotapp/hb-react-component-catalog'
import { useMemo } from 'react'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { defineMessages, useIntl } from 'react-intl'
import { ListingStatus } from '../../../constants/homeSearch'

interface ListingStatusNewProps {
  listing: ListingCompact | Listing
}

const MSG = defineMessages({
  forSale: {
    id: 'ListingStatusNew.forSale',
    defaultMessage: 'For sale'
  },
  comingSoon: {
    id: 'ListingStatusNew.comingSoon',
    defaultMessage: 'Coming soon'
  },
  salePending: {
    id: 'ListingStatusNew.salePending',
    defaultMessage: 'Pending'
  },
  activeUnderContract: {
    id: 'ListingStatusNew.activeUnderContract',
    defaultMessage: 'Active under contract'
  },
  sold: {
    id: 'ListingStatusNew.sold',
    defaultMessage: 'Sold'
  },
  hold: {
    id: 'ListingStatusNew.hold',
    defaultMessage: 'On Hold'
  },
  canceled: {
    id: 'ListingStatusNew.canceled',
    defaultMessage: 'Canceled'
  },
  expired: {
    id: 'ListingStatusNew.expired',
    defaultMessage: 'Expired'
  },
  offMarket: {
    id: 'ListingStatusNew.offMarket',
    defaultMessage: 'Off Market'
  },
  withdrawn: {
    id: 'ListingStatusNew.withdrawn',
    defaultMessage: 'Withdrawn'
  },
  farm: {
    id: 'General.farm',
    defaultMessage: 'Farm'
  },
  land: {
    id: 'General.land',
    defaultMessage: 'Land'
  },
  residential: {
    id: 'General.residential',
    defaultMessage: 'Residential'
  }
})

export const ListingStatusNew = ({ listing }: ListingStatusNewProps) => {
  const { theme } = useTheme()
  const intl = useIntl()

  const statusIcon = useMemo(() => {
    switch (listing.status) {
      case ListingStatus.ComingSoon:
        return statusComingSoon
      case ListingStatus.Pending:
      case ListingStatus.ActiveUnderContract:
        return statusPending
      case ListingStatus.Closed:
        return statusClosed
      case ListingStatus.Hold:
        return statusHold
      case ListingStatus.Canceled:
      case ListingStatus.Expired:
      case ListingStatus.OffMarket:
      case ListingStatus.Withdrawn:
        return statusNotAvailable
      case ListingStatus.Active:
      default:
        return statusForSale
    }
  }, [listing.status])

  const statusText = useMemo(() => {
    switch (listing.status) {
      case ListingStatus.ComingSoon:
        return intl.formatMessage(MSG.comingSoon)
      case ListingStatus.ActiveUnderContract:
        return intl.formatMessage(MSG.activeUnderContract)
      case ListingStatus.Pending:
        return `${intl.formatMessage(MSG.salePending)}`
      case ListingStatus.Closed:
        return intl.formatMessage(MSG.sold)
      case ListingStatus.Hold:
        return intl.formatMessage(MSG.hold)
      case ListingStatus.Canceled:
        return intl.formatMessage(MSG.canceled)
      case ListingStatus.Expired:
        return intl.formatMessage(MSG.expired)
      case ListingStatus.OffMarket:
        return intl.formatMessage(MSG.offMarket)
      case ListingStatus.Withdrawn:
        return intl.formatMessage(MSG.withdrawn)
      case ListingStatus.Active:
      default:
        return intl.formatMessage(MSG.forSale)
    }
  }, [listing.status])

  return (
    <Flex as='span'>
      <Box as='img' src={statusIcon} width={2.5} alt={statusText} />
      <Text as='span' size='xs' textColor={theme.isLightThemeType ? 'neutral.900' : 'white'} ml={1} fontWeight={600}>
        {statusText}
      </Text>
    </Flex>
  )
}
