import {
  Box,
  Button,
  Flex,
  Form,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast
} from '@homebotapp/hb-react-component-catalog'
import React, { useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { Listing } from '../../../api/gqlaxy/gql/generated/graphql'
import { useUpdateFavoriteListing } from '../../../hooks/listings/useUpdateFavoriteListings'
import { useUpdateClient } from '../../../hooks/listings/useUpdateClient'
import { useDispatch, useSelector } from 'react-redux'
import { setClientFavoriteListingsAlertCadenceDays } from '../../../actions/client'
import { selectClientFavoriteListingsAlertCadenceDays, selectClientId } from '../../../store/selectors/client'
import { useResetFavoriteListingsAlertCadence } from '../../../hooks/listings/useResetFavoriteListingsAlertCadence'
import { useRecoilState } from 'recoil'
import { firstFavoritePropertyState } from '../GoogleAutoComplete/state/isFirstFavoriteProperty'

export const MSG = defineMessages({
  alertPreferenceModalHeading: {
    id: 'HomeSearch.alertPreferenceModal.heading',
    defaultMessage: 'Manage alerts'
  },
  alertPreferenceModalSubheading: {
    id: 'HomeSearch.alertPreferenceModal.subheading',
    defaultMessage: 'How often do you want to get alerts on your favorite listings?'
  },
  alertPreferenceModalSubheadingInitial: {
    id: 'HomeSearch.alertPreferenceModal.subheadingInitial',
    defaultMessage: 'You favorited your first listing! How often do you want to get alerts on your favorite listings?'
  },
  alertPreferenceModalSubheadingCustom: {
    id: 'HomeSearch.alertPreferenceModal.subheadingCustom',
    defaultMessage: 'How often do you want to get alerts on {address}?'
  },
  save: {
    id: 'HomeSearch.alertPreferenceModal.save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'HomeSearch.alertPreferenceModal.cancel',
    defaultMessage: 'Cancel'
  },
  manage: {
    id: 'HomeSearch.alertPreferenceModal.manage',
    defaultMessage: 'Manage'
  },
  resetCustomListingSettings: {
    id: 'HomeSearch.alertPreferenceModal.resetCustomListingSettings',
    defaultMessage:
      'Do you want to reset your custom settings on individual listings in order to match your new default?'
  },
  yes: {
    id: 'HomeSearch.alertPreferenceModal.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'HomeSearch.alertPreferenceModal.no',
    defaultMessage: 'No'
  },
  successTitle: {
    id: 'HomeSearch.alertPreferenceModal.successTitle',
    defaultMessage: 'Success!'
  },
  successDescriptionDefaultOn: {
    id: 'HomeSearch.alertPreferenceModal.successDescriptionDefaultOn',
    defaultMessage: 'We’ll send you {cadence} alerts for your favorite properties.'
  },
  successDescriptionDefaultOff: {
    id: 'HomeSearch.alertPreferenceModal.successDescriptionDefaultOff',
    defaultMessage: 'We won’t send you alerts for your favorite properties.'
  },
  successDescriptionListingOn: {
    id: 'HomeSearch.alertPreferenceModal.successDescriptionListingOn',
    defaultMessage: 'We’ll send you {cadence} alerts for this property.'
  },
  successDescriptionListingOff: {
    id: 'HomeSearch.alertPreferenceModal.successDescriptionListingOff',
    defaultMessage: 'We won’t send you alerts for this property.'
  },
  errorMessage: {
    id: 'HomeSearch.alertPreferenceModal.errorMessage',
    defaultMessage: 'Error updating alert preference'
  },
  resetSuccessDescription: {
    id: 'HomeSearch.alertPreferenceModal.resetSuccessDescription',
    defaultMessage: 'All listings have been reset to that default.'
  },
  daily: {
    id: 'HomeSearch.alertPreferenceModal.daily',
    defaultMessage: 'Daily'
  },
  weekly: {
    id: 'HomeSearch.alertPreferenceModal.weekly',
    defaultMessage: 'Weekly'
  },
  never: {
    id: 'HomeSearch.alertPreferenceModal.never',
    defaultMessage: 'Never'
  }
})

export const ALERT_CADENCE_VALUES = ['daily', 'weekly', 'never', 'immediate'] as const
export type FavoriteListingAlertPreferenceTypes = (typeof ALERT_CADENCE_VALUES)[number] | null
export type ResetListingSettingsTypes = 'yes' | 'no'
export const ALERT_OFF_MIKASA = -1
export const ALERT_OFF_STRING: FavoriteListingAlertPreferenceTypes = 'never'

type ListingFavoriteAlertModalProps =
  | {
      mode: 'default'
      refetchFavoriteListings?: () => void
      listing?: never
      listingsAlertEnabled?: never
    }
  | {
      mode: 'listing'
      listing: Listing
      listingsAlertEnabled: boolean
      refetchFavoriteListings?: () => void
    }
  | {
      mode: 'initial'
      listing?: never
      listingsAlertEnabled?: never
      refetchFavoriteListings?: never
    }

/**
 * Takes an alertCadence number and returns the corresponding FavoriteListingAlertPreferenceTypes
 *
 * on the backend, it's stored as an integer, where 1 is daily, 7 is weekly, and -1 is never
 * @param alertCadence
 * @returns FavoriteListingAlertPreferenceTypes
 */
export const alertCadenceMapper = (alertCadence: number | null): FavoriteListingAlertPreferenceTypes => {
  switch (alertCadence) {
    case 1:
      return 'daily'
    case 7:
      return 'weekly'
    case -1:
      return 'never'
    default:
      return null
  }
}

/**
 * Allows us to sanitize the alertCadence value from the client to be stored on the backend
 * @param alertCadence as FavoriteListingAlertPreferenceTypes
 * @returns integer to represent the alertCadence selection
 */
const alertCadenceInverseMapper = (alertCadence: FavoriteListingAlertPreferenceTypes): number => {
  switch (alertCadence) {
    case 'daily':
      return 1
    case 'weekly':
      return 7
    case 'never':
      return -1
    default:
      return 0
  }
}

export const ListingFavoriteAlertModal = ({
  mode = 'default',
  listing,
  refetchFavoriteListings,
  listingsAlertEnabled
}: ListingFavoriteAlertModalProps) => {
  const [showAlertPreferenceModal, setShowAlertPreferenceModal] = useState(false)
  const handleAlertPreferenceModalClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setShowAlertPreferenceModal(true)
  }
  const intl = useIntl()
  const alertCadenceFromClientDefault = useSelector(selectClientFavoriteListingsAlertCadenceDays)
  const alertCadenceSelectionFromFavoriteListing = listing?.alertCadenceDays ?? alertCadenceFromClientDefault ?? 7
  const [alertCadenceFormSelection, setAlertCadenceSelection] = useState<FavoriteListingAlertPreferenceTypes>(
    alertCadenceMapper(alertCadenceSelectionFromFavoriteListing)
  )
  const [resetListingSettingsFormSelection, setResetListingSettingsFormSelection] =
    useState<ResetListingSettingsTypes>('no')

  const { mutateAsync: updateFavoriteListing } = useUpdateFavoriteListing()
  const { mutateAsync: updateClient } = useUpdateClient()
  const { mutateAsync: resetCustomListingSettings } = useResetFavoriteListingsAlertCadence()

  const [recoilFirstFavoriteProperty, setRecoilFirstFavoriteProperty] = useRecoilState(firstFavoritePropertyState)

  const toast = useToast()
  const dispatch = useDispatch()
  const clientId = useSelector(selectClientId) as string

  const updateListingAlertPreference = async () => {
    let updated = true
    try {
      if (mode === 'listing' && listing) {
        await updateFavoriteListing({
          listingId: listing.id,
          input: { alertCadenceDays: alertCadenceInverseMapper(alertCadenceFormSelection) }
        })
      }
    } catch (error) {
      updated = false
    }
    return updated
  }

  const updateDefaultAlertPreference = async () => {
    let updated = true
    try {
      const response = await updateClient({
        input: {
          favoriteListingsAlertCadenceDays: alertCadenceInverseMapper(alertCadenceFormSelection)
        }
      })
      dispatch(setClientFavoriteListingsAlertCadenceDays(alertCadenceInverseMapper(alertCadenceFormSelection)))
    } catch (error) {
      updated = false
    }
    return updated
  }

  const handleResetCustomListingsAlertSettings = async () => {
    let resetSuccess = true
    try {
      const listingsReset = await resetCustomListingSettings({
        clientId: clientId
      })
    } catch (error) {
      resetSuccess = false
    }
    return resetSuccess
  }

  useEffect(() => {
    if (recoilFirstFavoriteProperty === 'pending' && mode === 'initial') {
      setShowAlertPreferenceModal(true)
    }
  }, [recoilFirstFavoriteProperty])

  const handleSubmit = async () => {
    if (mode === 'default' || mode === 'initial') {
      const defaultAlertPreferenceUpdated = await updateDefaultAlertPreference()
      let listingsHaveBeenReset = false
      if (resetListingSettingsFormSelection === 'yes') {
        listingsHaveBeenReset = await handleResetCustomListingsAlertSettings()
        refetchFavoriteListings?.()
      }
      if (defaultAlertPreferenceUpdated) {
        const toastDescription =
          (alertCadenceFormSelection === ALERT_OFF_STRING
            ? intl.formatMessage(MSG.successDescriptionDefaultOff)
            : intl.formatMessage(MSG.successDescriptionDefaultOn, { cadence: alertCadenceFormSelection })) +
          ' ' +
          (listingsHaveBeenReset ? intl.formatMessage(MSG.resetSuccessDescription) : '')
        toast({
          title: intl.formatMessage(MSG.successTitle),
          status: 'success',
          description: toastDescription
        })
        setShowAlertPreferenceModal(false)
        if (mode === 'initial') {
          setRecoilFirstFavoriteProperty('false')
        }
      } else {
        toast({ description: intl.formatMessage(MSG.errorMessage), status: 'error' })
      }
    } else {
      // mode === 'listing'
      const alertPreferenceUpdated = await updateListingAlertPreference()
      if (alertPreferenceUpdated) {
        toast({
          title: intl.formatMessage(MSG.successTitle),
          status: 'success',
          description:
            alertCadenceFormSelection === ALERT_OFF_STRING
              ? intl.formatMessage(MSG.successDescriptionListingOff)
              : intl.formatMessage(MSG.successDescriptionListingOn, { cadence: alertCadenceFormSelection })
        })
        setShowAlertPreferenceModal(false)
        refetchFavoriteListings?.()
      } else {
        toast({ description: intl.formatMessage(MSG.errorMessage), status: 'error' })
      }
    }
  }

  return (
    <>
      {mode === 'listing' && (
        <IconButton
          icon={
            <Icon name='pencil' color={listingsAlertEnabled ? 'success.500' : 'neutral.500'} width={3} height={3} />
          }
          color={listingsAlertEnabled ? 'success.500' : 'neutral.500'}
          aria-label='Adjust your alert preferences'
          onClick={handleAlertPreferenceModalClick}
          variant='ghost'
          size={'sm'}
          tracking={{
            guid: '89EfxCQAgTvL9VMnBDXfVu',
            ui_context: 'Listing Card in Favorite Properties',
            descriptor: 'Open Listing Alert Preference Modal'
          }}
        />
      )}
      {mode === 'default' && (
        <Button
          size='sm'
          variant='ghost'
          colorScheme='primary'
          onClick={handleAlertPreferenceModalClick}
          tracking={{
            guid: 's6kymQ1QMsi9wkimrYmbav',
            ui_context: 'Favorite Listings Page',
            descriptor: 'Open Default Alert Preferences Modal'
          }}
        >
          <Box alignItems='center' display='flex'>
            <Icon name='pencil' mr={2} />
            <Text size='xs' fontWeight='bold' mb={0}>
              {intl.formatMessage(MSG.manage)}
            </Text>
          </Box>
        </Button>
      )}

      <Modal isOpen={showAlertPreferenceModal} onClose={() => setShowAlertPreferenceModal(false)}>
        <ModalOverlay />
        <ModalContent overflow='hidden' borderRadius={16} marginInline={[0, 5, '20%']} marginTop={[0, 6]} mb={[0, 8]}>
          <ModalHeader>
            <Heading as='h3' fontWeight='bold'>
              {intl.formatMessage(MSG.alertPreferenceModalHeading)}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <Form
            onSubmit={() => {
              handleSubmit()
            }}
            defaultValues={{
              alertCadence: alertCadenceMapper(alertCadenceSelectionFromFavoriteListing) ?? 'weekly',
              resetListingSetting: resetListingSettingsFormSelection
            }}
          >
            <ModalBody>
              <FormControl>
                <FormLabel>
                  {mode == 'listing' && !!listing
                    ? intl.formatMessage(MSG.alertPreferenceModalSubheadingCustom, {
                        address: listing?.address.number + ' ' + listing?.address.street
                      })
                    : mode == 'initial'
                    ? intl.formatMessage(MSG.alertPreferenceModalSubheadingInitial)
                    : intl.formatMessage(MSG.alertPreferenceModalSubheading)}
                </FormLabel>
                <RadioGroup
                  name='alertCadence'
                  onClick={e => {
                    const stringValue = (e.target as HTMLInputElement).value
                    setAlertCadenceSelection(stringValue as FavoriteListingAlertPreferenceTypes)
                  }}
                >
                  <Stack direction='column'>
                    <Radio value='daily'>{intl.formatMessage(MSG.daily)}</Radio>
                    <Radio value='weekly'>{intl.formatMessage(MSG.weekly)}</Radio>
                    <Radio value='never'>{intl.formatMessage(MSG.never)}</Radio>
                    {/* To add when alert catches up */}
                    {/* <Radio value='immediate'>Immediate</Radio> */}
                  </Stack>
                </RadioGroup>
              </FormControl>
              {mode === 'default' && (
                <FormControl>
                  <FormLabel pt={2}>{intl.formatMessage(MSG.resetCustomListingSettings)}</FormLabel>
                  <RadioGroup
                    name='resetListingSetting'
                    onClick={e => {
                      setResetListingSettingsFormSelection(
                        (e.target as HTMLInputElement).value as ResetListingSettingsTypes
                      )
                    }}
                  >
                    <Stack direction='column'>
                      <Radio value='yes'>{intl.formatMessage(MSG.yes)}</Radio>
                      <Radio value='no'>{intl.formatMessage(MSG.no)}</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              )}
            </ModalBody>
            <ModalFooter>
              <Flex flexDir='row' justifyContent='flex-end' gap={3}>
                <Button onClick={() => setShowAlertPreferenceModal(false)} variant='outline' colorScheme='primary'>
                  {intl.formatMessage(MSG.cancel)}
                </Button>
                <Button
                  type='submit'
                  colorScheme={'primary'}
                  variant='solid'
                  tracking={{
                    guid: '6YxWJCoTsetnxaNi4NbwBY',
                    ui_context: 'ListingFavoriteAlertModal',
                    descriptor:
                      mode === 'listing'
                        ? 'Submit button to set listing level alert preference'
                        : 'Submit button to set default alert preference'
                  }}
                >
                  {intl.formatMessage(MSG.save)}
                </Button>
              </Flex>
            </ModalFooter>
          </Form>
        </ModalContent>
      </Modal>
    </>
  )
}
