import { getClientIdFromBearerToken } from '../auth'

export const useGetClientId = (): string => {
  const queryParams = new URLSearchParams(location.search)
  const clientIdFromToken = getClientIdFromBearerToken()
  const clientId =
    clientIdFromToken ||
    queryParams.get('clientId') ||
    queryParams.get('impId') ||
    localStorage.getItem('impersonatedClientId')
  return encodeURIComponent(clientId)
}
