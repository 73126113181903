import { useCallback } from 'react'
import { UseMutationOptions, useQueryClient } from 'react-query'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import {
  ToggleFavoriteListingMutation,
  useToggleFavoriteListingMutation
} from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { QueryKey } from '../../constants/queryKey'
import { FavoriteListingsQuery, Listing, ListingCompact } from '../../api/gqlaxy/gql/generated/graphql'
import { useToast } from '@homebotapp/hb-react-component-catalog'
import { defineMessages, useIntl } from 'react-intl'
import { useGetClientId } from '../useGetClientId'

type Context = {
  listingId: string
  previousFavoriteListings: Listing[]
  favoriteListings: ListingCompact[]
}

export const MSG = defineMessages({
  removedFromFavorites: {
    id: 'Listing.ToggleFavoriteListings.Removed',
    defaultMessage: 'Removed from favorites'
  },
  addedToFavorites: {
    id: 'Listing.ToggleFavoriteListings.Added',
    defaultMessage: 'Added to favorites'
  }
})

export const useToggleFavoriteListing = (options?: UseMutationOptions<ToggleFavoriteListingMutation, unknown, {}>) => {
  const queryClient = useQueryClient()

  const toast = useToast()
  const intl = useIntl()

  const clientId = useGetClientId()

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // Local endpoint
  })

  const showSuccessToast = (description: string) => {
    toast({
      description,
      status: 'success',
      duration: 2000,
      position: 'bottom-left'
    })
  }

  // Optimistically update the favorite listings
  const handleMutate = useCallback(
    async ({ toggleFavoriteListingInput }: { toggleFavoriteListingInput: { listingId: string } }): Promise<Context> => {
      // stop refetching favorite listings to avoid overwriting optimistic updates
      await queryClient.cancelQueries([QueryKey.GetFavoriteListings, { clientId }])

      const previousFavorites = queryClient.getQueryData<FavoriteListingsQuery>([
        QueryKey.GetFavoriteListings,
        { clientId }
      ])

      const previousFavoriteListings = (previousFavorites?.favoriteListings?.listings ?? []) as Listing[]
      const isFavorited = previousFavoriteListings.some(({ id }) => id === toggleFavoriteListingInput.listingId)

      let favoriteListings = []
      if (isFavorited) {
        favoriteListings = previousFavoriteListings.filter(({ id }) => id !== toggleFavoriteListingInput.listingId)
        showSuccessToast(intl.formatMessage(MSG.removedFromFavorites))
      } else {
        favoriteListings = previousFavoriteListings.concat({ id: toggleFavoriteListingInput.listingId } as Listing)
        showSuccessToast(intl.formatMessage(MSG.addedToFavorites))
      }

      // Optimistically update to the new value
      queryClient.setQueryData([QueryKey.GetFavoriteListings, { clientId }], {
        favoriteListings: { listings: favoriteListings }
      })

      return {
        previousFavoriteListings,
        favoriteListings,
        listingId: toggleFavoriteListingInput.listingId
      }
    },
    [queryClient]
  )

  const handleSettled = useCallback(() => {
    queryClient.invalidateQueries(QueryKey.GetFavoriteListings)
  }, [queryClient])

  return useToggleFavoriteListingMutation(client, {
    ...options,

    onMutate: handleMutate,
    onSettled: options?.onSettled ?? handleSettled
  })
}
